.chat_newlist {
    position: relative;
    overflow: hidden
}

.chat_newlist li {
    margin-bottom: 5px;
    height: 31px;
    background: url(/public/img/score/ui_game/room_newlist.png) no-repeat
}

.chat_newlist li:last-child {
    margin-bottom: 0
}

.chat_newlist li a.premium_link {
    display: flex;
    flex-direction: column
}

.chat_newlist li a.premium_link .label {
    padding: 0 10px 10px;
    display: flex;
    flex-direction: column;
    flex: 1
}

.chat_newlist li a.premium_link .label:after {
    margin: 10px 0
}

.chat_newlist li a.premium_link .label>p {
    flex: 1
}

.chat_newlist li a.premium_link .photo img {
    margin-top: 10px
}

.chat_newlist .multi {
    margin-bottom: 7px;
    text-align: center
}

.chat_newlist ._w195 {
    width: 195px
}

.chat_newlist ._w195.inline {
    background-position: 0 -160px
}

.chat_newlist ._w198 {
    width: 198px
}

.chat_newlist ._w198.inline {
    background-position: -200px -190px
}

.chat_newlist ._h154 {
    height: 154px;
    background-position: 0 0
}

.chat_newlist ._h184 {
    height: 184px;
    background-position: -200px 0
}

.chat_newlist ._h214 {
    height: 214px;
    background-position: -400px 0
}

.chat_newlist ._h285 {
    height: 285px;
    background-position: -600px 0
}

.chat_newlist a {
    position: relative;
    display: block;
    color: #fff
}

.chat_newlist .multi a {
    position: relative;
    height: 100%
}

.chat_newlist .multi a p {
    line-height: 21px
}

.chat_newlist .multi a img {
    margin-top: 20px;
    border-radius: 5px
}

.chat_newlist .multi a .label {
    margin-top: 15px
}

.chat_newlist .multi._h214 a .label::after {
    display: block;
    content: '';
    width: 95px;
    height: 34px;
    background: url(/public/img/score/ui_game/sp_power_common.png?v=200709) no-repeat -400px -50px;
    margin: 0 auto
}

.chat_newlist .multi._h214 a .label._nochat::after {
    background-position: -400px -90px
}

.chat_newlist .multi._h285 a .label::after {
    display: block;
    content: 'ë¬´ë£Œìž…ìž¥í•˜ê¸°';
    margin-top: 10px;
    text-decoration: underline;
    color: #d6bc99
}

.chat_newlist .multi._h285 a .label._nochat::after {
    display: block;
    content: 'ìœ ì € í™ˆ ìž…ìž¥í•˜ê¸°';
    margin-top: 10px;
    text-decoration: underline;
    color: #d6bc99
}

.chat_newlist .multi a .title {
    height: 42px;
    overflow: hidden
}

.chat_newlist .multi a:hover .title {
    text-decoration: underline
}

.chat_newlist .inline a {
}

.chat_newlist .inline a p {
    margin: 0 7px 0 25px;
    line-height: 31px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.chat_newlist li a::before {
    display: block;
    width: 16px;
    height: 14px;
    position: absolute;
    left: 9px;
    top: 9px;
    background: url(/public/img/score/ui_game/room_newlist.png) no-repeat;
    content: ''
}

.chat_newlist li._brown:first-child a::before {
    background-position: 30px 0
}

.chat_newlist li._brown:nth-child(2) a::before {
    background-position: 0 -200px
}

.chat_newlist li._brown:nth-child(3) a::before {
    background-position: 0 -220px
}

.chat_newlist li._brown:nth-child(4) a::before {
    background-position: 0 -240px
}

.chat_newlist li._brown:nth-child(5) a::before {
    background-position: 0 -260px
}

.chat_newlist li._blue:first-child a::before {
    background-position: 30px 0
}

.chat_newlist li._blue:nth-child(2) a::before {
    background-position: -30px -200px
}

.chat_newlist li._blue:nth-child(3) a::before {
    background-position: -30px -220px
}

.chat_newlist li._blue:nth-child(4) a::before {
    background-position: -30px -240px
}

.chat_newlist li._blue:nth-child(5) a::before {
    background-position: -30px -260px
}

.chat_newlist._notab li._brown:first-child a::before {
    background-position: 0 -200px
}

.chat_newlist._notab li._brown:nth-child(2) a::before {
    background-position: 0 -220px
}

.chat_newlist._notab li._brown:nth-child(3) a::before {
    background-position: 0 -240px
}

.chat_newlist._notab li._brown:nth-child(4) a::before {
    background-position: -1px -260px
}

.chat_newlist._notab li._brown:nth-child(5) a::before {
    background-position: 0 -280px
}

.chat_newlist._notab li._blue:first-child a::before {
    background-position: -30px -200px
}

.chat_newlist._notab li._blue:nth-child(2) a::before {
    background-position: -30px -220px
}

.chat_newlist._notab li._blue:nth-child(3) a::before {
    background-position: -30px -240px
}

.chat_newlist._notab li._blue:nth-child(4) a::before {
    background-position: -30px -260px
}

.chat_newlist._notab li._blue:nth-child(5) a::before {
    background-position: -30px -280px
}

.chat_newlist li .premium_link img {
    width: 175px!important;
    height: 70px!important
}
