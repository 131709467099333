@charset "utf-8";body,button,input,textarea,a,label,select {
    font-family: -apple-system,BlinkMacSystemFont,malgun gothic,맑은 고딕,helvetica,apple sd gothic neo,sans-serif;
    font-size: 12px;
    color: #333
}

body,h1,h2,h3,h4,ul,dl,dd,p,th,td,form,button,input,textarea,fieldset {
    margin: 0;
    padding: 0
}

a {
    text-decoration: none
}

a:hover {
    text-decoration: underline
}

li {
    list-style: none
}

button {
    border: 0;
    background: 0 0
}

button::-moz-focus-inner {
    padding: 0!important;
    border: 0
}

input {
    outline-style: none
}

textarea {
    resize: none;
    outline-style: none
}

table {
    border-collapse: collapse
}

th,td {
    vertical-align: middle
}

th {
    font-weight: 400
}

button,label {
    cursor: pointer
}

em {
    font-style: normal
}

fieldset {
    border: none
}

legend {
    display: none
}

img {
    border: none
}

caption {
    display: none
}

.blind {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: -9999px;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    z-index: -1
}

body {
    -webkit-text-size-adjust: 100%
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input {
        letter-spacing:-.5px
    }
}
