.floating_banner {
    position: absolute;
    left: 478px;
    top: 389px;
    z-index: 10;
    font-size: 0
}

.floating_banner a {
    font-size: 0
}

.floating_banner .btn_close {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #fff;
    background: #000
}

#minigame_ad_banner {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 300px;
    height: 80px;
    top: 270px;
    z-index: 11;
    display: none!important
}

#minigame_ad_banner img {
    width: 100%;
    height: 80px;
    border-radius: 10px
}

.check_time_cotton {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 90;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Verdana,Arial,sans-serif
}

.check_time_cotton .check_time_area {
    height: 400px;
    width: 700px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    background: url(/public/img/score/ui_game/check_img/system_noti_bg.png) no-repeat
}

.check_time_cotton .check_time_area .game_cotton {
    height: 255px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 70px
}

.check_time_cotton .game_cotton_title_lottery {
    display: flex;
    justify-content: center
}

.check_time_cotton .game_cotton_title_lottery .title {
    height: 50px;
    width: 210px;
    background: url(/public/img/score/ui_game/check_img/loterry_system.png) no-repeat
}

.check_time_cotton .game_cotton_title_fx .title {
    height: 50px;
    width: 210px;
    background: url(/public/img/score/ui_game/check_img/fx_system.png) no-repeat
}

.check_time_cotton .game_cotton_content {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.check_time_cotton .game_cotton_content>p {
    font-size: 12px;
    line-height: 24px;
    color: #fff8ec;
    text-align: center
}

.check_time_cotton .game_cotton_content .date {
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    font-weight: 700
}
