@charset "utf-8";
@import url("https://fonts.google.com/specimen/Nanum Gothic"); 
@import url("https://fonts.google.com/specimen/Noto Sans KR");
#wrap {float:left; width:100%;}
* {box-sizing: border-box;	-ms-box-sizing: border-box;	-webkit-box-sizing: border-box;}
/*-------------------------------------------------------------------------------------*
 *  Header                                                                             *
 *-------------------------------------------------------------------------------------*/
#header_wrap {float:left; width:100%;}

.top_wrap {float:left; width:100%; height:46px; background:#e2e2e2;}
.top_box {margin:0px auto; width:1200px;}

.util {float:right;}
.util ul li {float:left; line-height:46px; margin:0 0 0 5px;}

.util_btn {background:#ededed; border:1px solid #d8d7d7; width:80px; height:32px; line-height:28px; display:inline-block; font-family:'Noto Sans KR', sans-serif; letter-spacing:-1px; color:#000000; font-size:12px; font-weight:700; text-align:center;}
.util_btn:hover {background:#0f4a99; border:1px solid #0f4a99; color:#ffffff;}

.gnb_wrap {float:left; width:100%; height:68px; background:linear-gradient(to left, #103f95 0%,  #0690af 100%);}
.gnb_box {margin:0px auto; width:1200px;}
.logo {float:left; width:285px; margin:6px 0 0 0;}
.gnb {float:right; text-align:center;}
.gnb ul li {float:left; line-height:28px; margin:16px 4px 0 4px;}
.gnb ul li a:hover {color:#ffa507;}

/*
#title_wrap {float:left; width:100%; height:44px; line-height:42px; margin:10px 0 0 0; background:url("../../../img/score/ui_game/bg_title.html") center top no-repeat;}
 */
.title {margin:0px auto; width:1200px; font-size:22px; font-family:'Noto Sans KR', sans-serif; color:#14805e; font-weight:700; letter-spacing:-1pt; text-shadow:1px 1px 1px rgba(0,0,0,0.5); padding:0 0 0 20px;;} 


/*-------------------------------------------------------------------------------------*
 *  Contents                                                                           *
 *-------------------------------------------------------------------------------------*/
#contents_wrap      {float:left; width:100%; min-height:400px; margin:30px 0 0 0;}
.contents_box       {margin:0px auto; width:1200px;}
.contents_left      {float:left; width:890px;}
.contents_right     {float:right; width:290px; margin:0 0 0 20px;}

.con_box00 {float:left; width:100%; margin:0 0 0 0;}
.con_box05 {float:left; width:100%; margin:5px 0 0 0;}
.con_box10 {float:left; width:100%; margin:10px 0 0 0;}
.con_box15 {float:left; width:100%; margin:15px 0 0 0;}
.con_box20 {float:left; width:100%; margin:20px 0 0 0;}
.con_box25 {float:left; width:100%; margin:25px 0 0 0;}
.con_box30 {float:left; width:100%; margin:30px 0 0 0;}
.con_box35 {float:left; width:100%; margin:35px 0 0 0;}
.con_box40 {float:left; width:100%; margin:40px 0 0 0;}
.con_box45 {float:left; width:100%; margin:45px 0 0 0;}
.con_box50 {float:left; width:100%; margin:50px 0 0 0;}

.po_link_wrap2 {float:left; width:100%;}
.po_link_title2 {float:left; width:100%;height:100px;background:url("../../../img/score/ui_game/title.jpg") center top no-repeat;color:#33ffff;font-weight:bold;font-size:26px;padding:12px 0 0 20px}
.po_link2 {float:left; width:100%; border-left:0px solid #dddddd; border-right:0px solid #dddddd; border-bottom:0px solid #dddddd;}

.po_link_wrap {float:left; width:100%;}
.po_link_title {float:left; width:100%;}
.po_link {float:left; width:100%; border-left:0px solid #dddddd; border-right:0px solid #dddddd; border-bottom:0px solid #dddddd;}

.link_wrap {float:left; width:860px; }
.link_wrap ul li {float:left;  height:220px;}
.link_box {position:relative; float:left; width:260px; height:220px; background:#09a780; text-align:center; margin:0 13px 0 13px;}
.link_box_in { display:inline-block; line-height:26px; width:190px; height:76px; border-radius:100px; background:#ffffff; font-size:21px; font-family:'Noto Sans KR', sans-serif; letter-spacing:-1px; color:#525252; font-weight:400; padding:10px 0 0 0; margin:32px 0 10px 0;}
.link_box_text { line-height:40px; font-size:19px; font-family:'Noto Sans KR', sans-serif; letter-spacing:-1px; color:#ffffff; font-weight:400;}
.link1 {position: absolute; top:10px; left:25px;}

.board_wrap {float:left; width:435px;}
.board_title {float:left; width:435px; height:46px; border:1px solid #dddddd;}
.board_title ul li {float:left; line-height:40px; margin:0 25px 0 25px;}
.board_title_text {font-size:18px; font-family:'Noto Sans KR', sans-serif; letter-spacing:-1px; color:#b6b6b6; font-weight:400; }
.board_title_text_on {font-size:18px; font-family:'Noto Sans KR', sans-serif; letter-spacing:-1px; color:#0f4898; font-weight:700; }
.board {float:left; width:435px; margin:8px 0 0 0;}
.board td {line-height:34px; font-size:15px; font-family:'Noto Sans KR', sans-serif; letter-spacing:-1px; font-weight:400; }

.login_wrap {float:left; width:290px; height:180px; background:#f3f3f3; border:1px solid #e7e5e5; padding:20px;}

.login_box {float:left; width:250px;}
.login_input_box {float:left; width:180px; line-height:32px;}
.login_login {float:left; width:68px; margin:0 0 0 2px;}

.login_idpw {float:left; width:250px; line-height:40px; color:#656565;}
.login_idpw a {color:#656565;}
.login_join {float:left; width:250px;}
.login_join_btn {float:left; width:250px; line-height:34px; background:#e6e6e6; border:1px solid #d9d8d8; color:#656565; font-weight:900; text-align:center;}

.login_join_btn2 {float:left; width:123px; line-height:34px; background:#e6e6e6; border:1px solid #d9d8d8; color:#656565; font-weight:900; text-align:center;}
.login_in {float:left; width:250px; font-size:18px; font-family:'Noto Sans KR', sans-serif; letter-spacing:-1px; color:#555555; font-weight:400;}

/*-------------------------------------------------------------------------------------*
 *  파워볼                                                                             *
 *-------------------------------------------------------------------------------------*/
.po_wrap_new {position:relative; width:890px; height:670px; background:url("../../../img/score/ui_game/bg_powerball.png") center top no-repeat; margin:35px 0 35px 0;}

.po_box_wrap {position:absolute; width:205px; height:600px; top:182px; left:25px; overflow-y:scroll;}
.po_box ul li {display:inline-block;}
.po_bottom_btn1 {position:absolute; bottom:19px; left:23px;}

.po_box_wrap_right {position:absolute; width:205px; height:600px; top:182px; right:25px; overflow-y:scroll;}
.po_box_right ul li {display:inline-block;}
.po_bottom_btn2 {position:absolute; bottom:19px; right:23px;}

.po_center_wrap {position:absolute; width:350px; top:120px; left:235px;}
.po_center_wrap_new {position:absolute; width:340px; top:122px; left:280px;}

.po_center_data {float:left; width:350px; height:32px; line-height:32px; text-align:center; font-size:12px; font-family:'Noto Sans KR', sans-serif; letter-spacing:0px; color:#c08d7b; font-weight:700;}
.po_center_ball {float:left; width:355px; height:255px; text-align:center; margin:0 0 0 0; color:#c08d7b;}
.po_center_table {float:left; width:350px; height:110px; text-align:center; margin:0 0 0 0; color:#442613;}

.po_conter_bet_wrap {float:left; width:241px; margin:0 0 0 56px;}
.po_conter_bet1 {float:right; margin:20px 0 0 0px;}
.po_conter_bet1	ul li {float:left; margin:0 0 0 0;}

.po_conter_bet1 a.Odd{background-position:0px -1px;}
.po_conter_bet1 a.Even{background-position:-40px -1px;}
.po_conter_bet1 a.Und{background-position:-79px -1px;}
.po_conter_bet1 a.Ovr{background-position:-119px -1px;}

.po_conter_bet1 a.Odd:hover, .po_conter_bet1 a.Odd.On{background-position:0px -37px;}
.po_conter_bet1 a.Even:hover, .po_conter_bet1 a.Even.On{background-position:-40px -37px;}
.po_conter_bet1 a.Und:hover, .po_conter_bet1 a.Und.On{background-position:-79px -37px;}
.po_conter_bet1 a.Ovr:hover, .po_conter_bet1 a.Ovr.On{background-position:-119px -37px;}


.po_conter_bet2 {float:right;}
.po_conter_bet2	ul li {float:left; margin:0 0 0 0;}

.po_conter_bet2 a.Odd.P{background-position:0px -1px;}
.po_conter_bet2 a.Even.P{background-position:-40px -1px;}
.po_conter_bet2 a.Und.P{background-position:-79px -1px;}
.po_conter_bet2 a.Ovr.P{background-position:-119px -1px;}

.po_conter_bet2 a.Odd.P:hover, .po_conter_bet1 a.Odd.P.On{background-position:0px -73px;}
.po_conter_bet2 a.Even.P:hover, .po_conter_bet1 a.Even.P.On{background-position:-40px -73px;}
.po_conter_bet2 a.Und.P:hover, .po_conter_bet1 a.Und.P.On{background-position:-79px -73px;}
.po_conter_bet2 a.Ovr.P:hover, .po_conter_bet1 a.Ovr.P.On{background-position:-119px -73px;}

.po_conter_bet3 {float:right; margin:15px 0 0 0;}
.po_conter_bet3 ul li {line-height:34px;}
.po_conter_bet3_input  {background-color:#ffe2ba; border:1px solid #d4ae79; padding:5px 0 3px 5px; width:156px; height:30px; border-radius:5px; color:#442613; padding:0 0 0 10px; font-size:16px;}

.po_conter_bet4 {float:left; margin:2px 0 0 0;}
.po_conter_bet4 ul li {float:left;}


/*-------------------------------------------------------------------------------------*
 *  Footer                                                                             *
 *-------------------------------------------------------------------------------------*/
#footer_wrap {float:left; width:100%; height:160px; margin:50px 0 0 0; text-align:center;}
.footer_menu {float:left; width:100%; height:68px; text-align:center; background:#eaeaea;}
.footer_menu ul li {display:inline; line-height:68px; margin:0 2px 0 2px;}
.footer_menu ul li a {color:#946d42;}
.footer_menu ul li a:hover {color:#eecd69;}
.footer_menu_btn {border:1px solid #d1d1d1; width:150px; height:40px; line-height:36px; font-family:'Noto Sans KR', sans-serif; letter-spacing:-1px; text-align:center; display:inline-block; color:#848484; font-size:12px; font-weight:700;}
.footer_menu_btn:hover {background:#777777; border:1px solid #777777; color:#ffffff; }
.footer {float:left; width:100%; text-align:center; padding:20px 0 25px 0; color:#9c9c9c; line-height:20px;}


/*-------------------------------------------------------------------------------------*
 *  Style                                                                              *
 *-------------------------------------------------------------------------------------*/
/* 폰트 스타일 */
.font01 {color:#ffffff; font-weight:900;}
.font02 {color:#000000; font-weight:900;}
.font03 {color:#929292;}
.font04 {color:#484848;}
.font05 {color:#ffe418; font-weight:900;}
.font06 {color:#14805e; font-weight:900;}
.font07 {color:#00eaff; font-weight:900;}
.font08 {color:#79d501; font-weight:900;}
.font09 {color:#56e0fc; font-weight:900;}
.font10 {color:#e9270f; font-weight:900;}

.font11 {color:#6f8193; font-weight:900; font-size:22px; line-height:35px; font-family:Arial;}
.font12 {color:#59aeb1;font-weight:900; font-size:22px;line-height:35px; font-family:'Noto Sans KR', sans-serif; text-shadow:1px 1px 1px #000000;}
.font13 {color:#b0977f;font-weight:900;}
.font14 {color:#818fa0;}
.font15 {color:#ffffff;font-weight:900; font-size:26px;line-height:35px; font-family:'Noto Sans KR', sans-serif; text-shadow:1px 1px 1px #000000}

/* 인풋 스타일 */
.input_login  {background-color:#ffffff; border:1px solid #dfdfdf; padding:5px 0 3px 5px; width:180px; height:30px; border-radius:0px; color:#000000; padding:0 0 0 10px; font-size:16px;}
.input_search {background-color:#161616; border:1px solid #000000; padding:8px 0 6px 5px; border-radius:3px;}
.input1       {background-color:#161616; border:1px solid #000000; padding:8px 0 6px 5px; border-radius:3px; color:#dfd7b9;}
.input2       {background-color:#161616; border:1px solid #000000; padding:8px 0 6px 5px; border-radius:3px;}
